<template>
  <div>
    <div
      v-if="selectedSection == 'chooseLocation'"
      class="appCheckout"
    >
      <!-- <div class="appCheckout__header">
        Choose where will this toy arrives
      </div> -->
      <div class="appCheckout__container">
        <img
          :src="resolveImage(defaultPicture)"
          class="appCheckout__container__image"
        >
        <div class="appCheckout__container__details">
          <div class="appCheckout__container__details__title">
            {{ name }}
          </div>
          <!-- <div class="appCheckout__container__details__description">
            {{ description }}
          </div> -->
          <div class="appCheckout__container__details__price">
            £{{ formatPrice(productPrice) }}
          </div>
        </div>
      </div>
      <div style="display: none">
        {{ selectedAddressId }}
      </div>
      <div class="appCheckout__center">
        <img
          src="../assets/images/arrow_down.png"
          class="appCheckout__center__arrow"
        >
      </div>

      <div v-if="getDeliveryAddresses.length > 0">
        <div
          v-for="address in default_address.filter(
            (item) => item.is_default == true
          )"
          :key="address.id"
          class="appCheckout__address"
          :class="{ 'active-address': activeAddressIndex === address.id }"
          @click="selectFirstAddress(address.id)"
        >
          <div v-if="address.type == 'Home'">
            <img
              src="../assets/images/address_home.png"
              class="appProfileAdress__house"
              :alt="address.type"
            >
          </div>
          <div v-else-if="address.type == 'Work'">
            <img
              src="../assets/images/address_work.png"
              class="appProfileAdress__house"
              :alt="address.type"
            >
          </div>
          <div v-else-if="address.type == 'Grandma'">
            <img
              src="../assets/images/address_grandma.png"
              class="appProfileAdress__house"
              :alt="address.type"
            >
          </div>
          <div v-else-if="address.type == 'Grandpa'">
            <img
              src="../assets/images/address_grandpa.png"
              class="appProfileAdress__house"
              :alt="address.type"
            >
          </div>
          <div v-else-if="address.type == 'Other'">
            <img
              src="../assets/images/address_other.png"
              class="appProfileAdress__house"
              :alt="address.type"
            >
          </div>

          <div class="appCheckout__address__content">
            <div class="appCheckout__address__details">
              <div class="appCheckout__address__details__name">
                {{ address.type }}
                <span
                  v-if="address.is_default"
                  class="isDefault"
                >Default</span>
              </div>
              <div class="appCheckout__address__details__description">
                {{ address.address }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="addresses.length > 0"
          class="notThisAddress"
          @click="showAllAddresses"
        >
          Not this address
        </div>

        <div v-if="hiddenAddresses">
          <div
            v-for="address in addresses.filter(
              (item) => item.is_default == false
            )"
            :key="address.id"
            class="appCheckout__address"
            :class="{ 'active-address': activeAddressIndex === address.id }"
            style="border: 1px dashed #7a6159"
            @click="selectAddress(address.id)"
          >
            <div v-if="address.type == 'Home'">
              <img
                src="../assets/images/address_home.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Work'">
              <img
                src="../assets/images/address_work.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Grandma'">
              <img
                src="../assets/images/address_grandma.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Grandpa'">
              <img
                src="../assets/images/address_grandpa.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Other'">
              <img
                src="../assets/images/address_other.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>

            <div class="appCheckout__address__content">
              <div class="appCheckout__address__details">
                <div class="appCheckout__address__details__name">
                  {{ address.type }}
                  <span
                    v-if="address.is_default"
                    class="isDefault"
                  >Default</span>
                </div>
                <div class="appCheckout__address__details__description">
                  {{ address.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="notifyGuardian"
          @click="notifyGuardianAction()"
        >
          <img
            src="../assets/images/bell.png"
            alt="Bell"
            width="15"
          > Click
          here to notify your guardian to add an address
        </div>
      </div>

      <AppErrorMessage :message="error" />
      <AppButton
        text="Send"
        :color="buttonColor"
        :mini="true"
        class="appCheckout__button"
        @click="addOrder"
      />
    </div>
    <div
      v-if="selectedSection == 'yourReceipt'"
      id="yourReceipt"
      class="appCheckout"
    >
      <!-- <div class="appCheckout__header">
        Bonus points
      </div> -->
      <div class="appCheckout__receipt">
        <div class="appCheckout__receipt__top">
          <div class="appCheckout__container__details__title">
            Your reciept
          </div>
        </div>
        <div class="appCheckout__receipt__content">
          <div class="appCheckout__receipt__content__item">
            <div class="appCheckout__receipt__content__item__name">
              {{ name }}
            </div>
            <div class="appCheckout__receipt__content__item__price">
              £{{ formatPrice(getLastOrder.price_without_tva) }}
            </div>
          </div>
          <div class="appCheckout__receipt__content__item">
            <div class="appCheckout__receipt__content__item__name">
              Delivery Cost
            </div>
            <div class="appCheckout__receipt__content__item__price">
              £{{ formatPrice(getLastOrder.cart.delivery_cost) }}
            </div>
          </div>
          <div class="appCheckout__receipt__content__item">
            <div class="appCheckout__receipt__content__item__name">
              V.A.T
            </div>
            <div class="appCheckout__receipt__content__item__price">
              £{{ formatPrice(getLastOrder.tva_value) }}
            </div>
          </div>
          <div class="appCheckout__receipt__content__line" />
          <div class="appCheckout__receipt__content__item">
            <div
              class="appCheckout__receipt__content__item__price"
              style="color: #36d5f2"
            >
              Total
            </div>
            <div
              class="appCheckout__receipt__content__item__price"
              style="color: #36d5f2"
            >
              £{{
                formatPrice(
                  getLastOrder.product_net_price +
                    Number(getLastOrder.cart.delivery_cost)
                )
              }}
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="appCheckout__game">
        <div class="appCheckout__game__title">
          How much will be remaining?
        </div>
        <div class="appCheckout__game__wallets">
          <AppDashboardWallet
            v-if="fromGoalOrStore == 'goal'"
            wallet="Spend"
            :balance="remainingMoney"
            class="appCheckout__game__wallets__item"
          />
          <AppDashboardWallet
            v-else
            wallet="Spend"
            :balance="getSpendWalletBalance"
            class="appCheckout__game__wallets__item"
          />
          <AppCostWallet
            :balance="productPrice"
            class="appCheckout__game__wallets__item"
          />
        </div>
        <AppInput
          type="number"
          placeholder="Your answer is: "
          class="appCheckout__game__input"
          v-model="gameResponse"
          @input="validateButton"
        />
        <div class="appCheckout__game__actions">
          <AppButton
            text="Answer"
            :color="answerColor"
            :mini="true"
            class="appCheckout__game__actions__button"
            @click="verifyAnswer"
          >
          </AppButton>
        </div>
       
      </div> -->
      <div class="thankYouContainer">
        <AppButton
          text="Thank You!"
          color="red"
          :mini="true"
          class="
            appCheckout__game__actions__button
            bg-[#58C1E1]
            rounded
            py-2
            px-8
            text-white text-sm
            font-bold
          "
          style="
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="thankYou"
        />
      </div>
    </div>

    <!-- <div v-if="selectedSection == 'bonusPoints'" class="bonusPoints__correct">
      <div
        v-if="this.currentPointsNumber > 0"
        class="bonusPoints__correct__text"
      >
        Correct!
      </div>
      <div
        v-if="this.currentPointsNumber > 0"
        class="bonusPoints__correct__text"
      >
        +{{ currentPointsNumber }} Sonik Points
      </div>

      <div
        v-if="this.currentPointsNumber == 0"
        class="bonusPoints__correct__text"
      >
        Incorrect!
      </div>
      <div
        v-if="this.currentPointsNumber == 0"
        class="bonusPoints__correct__text"
      >
        No Sonik Points
      </div>
      <AppButton
        text="Close"
        color="blue"
        :mini="true"
        class="bonusPoints__button"
        @click="closeModal"
      >
      </AppButton>
    </div> -->
  </div>
</template>

<script>
  import AppButton from "../components/AppButton";
  import AppErrorMessage from "../components/AppErrorMessage";
  // import AppDashboardWallet from "@/components/AppDashboardWallet";
  // import AppCostWallet from "@/components/AppCostWallet";
  // import AppInput from "@/components/AppInput";

  import { mapActions } from "vuex";
  import { mapGetters } from "vuex";

  import config from "../config";

  export default {
    components: {
      AppButton,
      AppErrorMessage,
      // AppDashboardWallet,
      // AppCostWallet,
      // AppInput,
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      defaultPicture: {
        type: String,
        default: "",
      },
      productId: {
        type: Number,
        default: 0,
      },
      goalId: {
        type: Number,
        default: 0,
      },
      productPrice: {
        // type: Number,
        // default: 0
      },
      fromGoalOrStore: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        activeAddressIndex: null,
        currentPointsNumber: 0,
        selectedSection: "chooseLocation",
        addresses: [],
        default_address: [],
        selectedAddressId: null,
        selected: [],
        error: "",
        buttonColor: null,
        config: [],
        gameResponse: null,
        answerColor: null,
        pointsNumber: 0,
        hiddenAddresses: false,
      };
    },
    computed: {
      ...mapGetters([
        "getSpendWalletBalance",
        "getSpendWalletAndGoalsBalance",
        "getDeliveryAddresses",
        "getLastOrder",
      ]),
      remainingMoney() {
        return Number(this.getSpendWalletBalance).toFixed(2);
      },
    },
    created() {
      this.config = config;
      this.default_address = this.getDeliveryAddresses.filter(
        (item) => item.is_default == true
      );
      this.addresses = this.getDeliveryAddresses.filter(
        (item) => item.is_default == false
      );
      // this.addresses = this.getDeliveryAddresses;
      // this.addresses.forEach((address) => {
      //   address.selected = null;
      // });

      // console.log(this.getLastOrder);
      // console.log(this.getDeliveryAddresses);
      // console.log(this.getLastOrder.product_net_price);
    },
    methods: {
      ...mapActions([
        "sendOrder",
        "fetchUser",
        "addPoints",
        "notifyGuardianAction",
      ]),
      resolveImage(src) {
        return config.HOST + src;
      },
      showAllAddresses() {
        this.hiddenAddresses = !this.hiddenAddresses;
      },
      selectAddress(addressId) {
        this.selectedAddressId = addressId;
        this.buttonColor = "blue";

        if (this.activeAddressIndex === addressId) {
          this.activeAddressIndex = null;
        } else {
          this.activeAddressIndex = addressId;
        }
      },
      selectFirstAddress(addressId) {
        this.selectedAddressId = addressId;
        this.buttonColor = "blue";

        if (this.activeAddressIndex === addressId) {
          this.activeAddressIndex = null;
        } else {
          this.activeAddressIndex = addressId;
        }
      },
      thankYou() {
        this.fetchUser();
        location.reload();
      },
      addOrder() {
        if (this.selectedAddressId == null) {
          this.error = "Invalid address!";
        } else {
          var details = {
            delivery_address_id: this.selectedAddressId,
            status: "paid",
            product_id: this.productId,
            goal_id: this.goalId,
            quantity: 1,
            by_from: this.fromGoalOrStore,
          };

          // console.log(details);
          this.sendOrder(details);
          this.error = "";
          this.selectedSection = "yourReceipt";

          // setTimeout(function () {
          //     this.game = true;
          // }, 2000);

          // location.href = "#yourReceipt";
          // document.getElementById('yourReceipt').scrollIntoView();
          // window.location.hash = '#yourReceipt';
          // window.scrollTo(0, 0);
        }
      },
      validateButton() {
        if (this.gameResponse.length > 0) {
          this.answerColor = "blue";
        } else {
          this.answerColor = null;
        }
      },
      verifyAnswer() {
        if (this.gameResponse.length > 0) {
          // console.log("raspunsul meu");
          // console.log(this.gameResponse);
          // console.log("raspuns corect");
          // console.log(
          //   Math.max(this.getSpendWalletBalance, this.productPrice) -
          //   Math.min(this.getSpendWalletBalance, this.productPrice)
          // );
          if (
            this.gameResponse ==
            Math.max(this.getSpendWalletBalance, this.productPrice) -
              Math.min(this.getSpendWalletBalance, this.productPrice)
          ) {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = (this.productPrice / 2).toFixed(2);
            // console.log(this.currentPointsNumber);
            // let payload = {
            //   points: this.currentPointsNumber,
            //   order_id: this.$store.state.lastOrderId,
            //   game_nr: 2,
            // };
            // this.addPoints(payload);
          } else {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = 0;
          }
        } else {
          this.answerColor = null;
          this.gameResponse = "";
        }

        // console.log(this.gameResponse);

        this.fetchUser();
      },
      closeModal() {
        if (this.fromGoalOrStore == "goal") {
          location.reload();
        }
        this.$emit("closeModal", true);

        this.fetchUser();
      },
    },
  };
</script>

<style lang="scss">
  .address_active {
    border: 3px solid #36d5f2;
  }
  .appCheckout {
    width: auto;

    &__game {
      width: 100%;

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 40px;
        line-height: 40px;
        color: #7a615a;
        margin-top: 50px;
        width: 100%;
      }

      &__wallets {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;

        &__item {
          height: 100%;
          width: 100%;
        }
      }

      &__input {
        width: 100% !important;
      }

      &__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        &__button {
          margin-top: 40px;
          width: 50%;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 20px;
      line-height: 21px;
      color: #ff4f79;
      margin: auto;
      margin-bottom: 40px;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &__image {
        width: 100%;
      }

      &__details {
        width: 100%;

        &__title {
          font-size: 30px;
          line-height: 21px;
          color: #7a615a;
          padding: 10px;
          margin-left: 20px;
        }
        &__description {
          font-size: 25px;
          line-height: 21px;
          color: #7a615a;
          padding: 10px;
          margin-left: 20px;
        }

        &__price {
          font-size: 25px;
          line-height: 25px;
          color: #36d5f2;
          padding: 10px;
          margin-left: 20px;
        }
      }
    }

    &__center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 20px;

      &__arrow {
        width: 30px;
      }
    }

    &__address {
      width: 100%;
      min-height: 100px;
      max-height: 300px;
      border: 2px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 10px !important;
      display: flex;
      // grid-template-columns: 1fr 9fr;
      align-items: center;
      margin-bottom: 20px;

      &__image {
        padding: 20px;
        width: 100px;
        height: 100px;
      }

      &__details {
        width: 100%;
        padding: 20px;

        &__name {
          font-size: 30px;
          line-height: 25px;
          color: #7a615a;
          display: flex;
          align-items: center;
        }

        &__description {
          font-size: 20px;
          line-height: 21px;
          color: #7a615a;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
    }

    // &__button {
    //   margin-left: 30px;
    //   margin-right: 30px;
    // }

    &__receipt {
      width: auto;
      padding: 10px;
      background: #ffffff;
      // border-radius: 20px;

      &__top {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-bottom: dotted #e5e5e5;
      }

      &__content {
        border-radius: 20px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);

        &__item {
          width: 100%;
          padding: 20px;
          display: grid;
          grid-template-columns: 10fr 3fr;

          &__name {
            font-size: 18px;
            line-height: 21px;
            color: #7a615a;
          }

          &__price {
            font-size: 20px;
            line-height: 25px;
            color: #7a615a;
            margin-right: 20px;
          }
        }

        &__line {
          width: 100%;
          height: 3px;
          background-color: #e5e5e5;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .appCheckout__address {
      margin-left: -35px;
      margin-right: -30px;
      width: auto;
    }
    .appCheckout__address__image {
      height: 100px;
      width: 100px;
      margin-top: 10px;
    }
    .appCheckout__address__details {
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
    .appCheckout__game__wallets {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
    .appCheckout__game__actions__button {
      width: 100%;
    }
  }

  @media screen and (max-width: 850px) {
    .appCheckout__address {
      border-radius: 20px;
      margin: 10px 0;
    }
    .appCheckout__address__details__name {
      font-size: 18px;
    }
    .appCheckout__address__details__description {
      font-size: 16px;
      line-height: 16px;
      margin-top: 5px;
    }
    .active-address {
      background: #36d5f2;
      border-color: #36d5f2;
      .appCheckout__address__details__name,
      .appCheckout__address__details__description {
        color: white;
      }
    }
  }

  .appCheckout__address {
    border-radius: 20px;
    margin: 10px 0;
  }
  .appCheckout__address__details__name {
    font-size: 18px;
  }
  .appCheckout__address__details__description {
    font-size: 16px;
    line-height: 16px;
    margin-top: 5px;
  }
  .active-address {
    background: #36d5f2;
    border-color: #36d5f2;
    .appCheckout__address__details__name,
    .appCheckout__address__details__description {
      color: white;
    }
  }
  .appProfileAdress__house {
    max-width: 50px;
    max-height: 50px;
    margin: 0;
    margin-right: 10px;
    margin-left: 20px;
  }

  .thankYouContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .isDefault {
    font-family: "Ember", sans-serif;
    font-size: 12px;
    background: #ff4f79;
    color: white;
    height: 20px;
    width: 60px;
    border-radius: 6px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notThisAddress {
    color: #36d5f2;
    margin: 15px 0 25px 0;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
  }
  .notifyGuardian {
    display: flex;
    align-items: center;
    background: #442528;
    width: 50%;
    margin: 0 auto;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    img {
      margin-right: 10px;
    }
  }
</style>
