<template>
  <div>
    <div
      v-if="selectedSection == 'gameCheck'"
      class="appCheckout__game"
    >
      <div class="appCheckout__game__title">
        How much will be remaining?
      </div>
      <div class="appCheckout__game__wallets">
        <AppDashboardWallet
          v-if="fromGoalOrStore == 'goal'"
          wallet="Spend"
          :balance="formatPrice(remainingMoney)"
          class="appCheckout__game__wallets__item"
        />
        <AppDashboardWallet
          v-else
          wallet="Spend"
          :balance="formatPrice(getSpendWalletBalance)"
          class="appCheckout__game__wallets__item"
        />
        <AppCostWallet
          :balance="formatPrice(productPrice)"
          class="appCheckout__game__wallets__item"
        />
      </div>
      <AppInput
        v-model="gameResponse"
        type="number"
        placeholder="Your answer is: "
        class="appCheckout__game__input"
        @input="validateButton"
      />
      <div class="appCheckout__game__actions">
        <AppButton
          text="Answer"
          :color="answerColor"
          :mini="true"
          class="
            appCheckout__game__actions__button
            bg-[#58C1E1]
            rounded
            py-2
            px-8
            text-white text-sm
            font-bold
          "
          @click="verifyAnswer"
        />
      </div>
    </div>
    <div
      v-if="selectedSection == 'bonusPoints'"
      class="bonusPoints__correct"
    >
      <div
        v-if="this.currentPointsNumber > 0"
        class="bonusPoints__correct__text"
      >
        Correct!
      </div>
      <div
        v-if="this.currentPointsNumber > 0"
        class="bonusPoints__correct__text"
      >
        +{{ currentPointsNumber }} Sonik Points
      </div>

      <div
        v-if="this.currentPointsNumber == 0"
        class="bonusPoints__correct__text"
      >
        Incorrect!
      </div>
      <div
        v-if="this.currentPointsNumber == 0"
        class="bonusPoints__correct__text"
      >
        No Sonik Points
      </div>
      <AppButton
        text="Go to checkout"
        color="blue"
        :mini="true"
        class="
          bonusPoints__button
          bg-[#58C1E1]
          rounded
          py-2
          px-8
          text-white text-sm
          font-bold
        "
        @click="openCheckout"
      />
    </div>
  </div>
</template>

<script>
  import AppButton from "../components/AppButton";
  // import AppErrorMessage from "@/components/AppErrorMessage";
  import AppDashboardWallet from "../components/AppDashboardWallet";
  import AppCostWallet from "../components/AppCostWallet";
  import AppInput from "../components/AppInput";

  import { mapActions } from "vuex";
  import { mapGetters } from "vuex";

  import config from "../config";

  export default {
    components: {
      AppButton,
      // AppErrorMessage,
      AppDashboardWallet,
      AppCostWallet,
      AppInput,
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      defaultPicture: {
        type: String,
        default: "",
      },

      productId: {
        type: Number,
        default: 0,
      },
      productPrice: {
        // type: Number,
        // default: 0
      },
      fromGoalOrStore: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        currentPointsNumber: 0,
        selectedSection: "gameCheck",
        addresses: [],
        selectedAddressId: null,
        selected: [],
        error: "",
        buttonColor: null,
        config: [],
        gameResponse: null,
        answerColor: null,
        pointsNumber: 0,
      };
    },
    computed: {
      ...mapGetters([
        "getSpendWalletBalance",
        "getSpendWalletAndGoalsBalance",
        "getDeliveryAddresses",
        "getLastOrder",
      ]),
      remainingMoney() {
        return Number(this.getSpendWalletBalance).toFixed(2);
      },
    },
    created() {
      this.config = config;
      this.addresses = this.getDeliveryAddresses;
      this.addresses.forEach((address) => {
        address.selected = null;
      });

      // console.log(this.getLastOrder);
      // console.log(this.getDeliveryAddresses);
    },
    methods: {
      ...mapActions(["sendOrder", "fetchUser", "addPoints"]),
      resolveImage(src) {
        return config.HOST + src;
      },
      selectAddress(addressId, index) {
        this.selectedAddressId = addressId;
        this.buttonColor = "blue";
        this.addresses.forEach((address) => {
          if (address.id == addressId) {
            this.addresses[index].selected = "active-address";
          } else {
            address.selected = null;
          }
        });
      },
      openCheckout() {
        this.$emit("openCheckout");
      },
      addOrder() {
        if (this.selectedAddressId == null) {
          this.error = "Invalid address!";
        } else {
          var details = {
            delivery_address_id: this.selectedAddressId,
            staus: "paid",
            product_id: this.productId,
            quantity: 1,
            by_from: this.fromGoalOrStore,
          };
          // console.log(details);
          this.sendOrder(details);
          this.error = "";
          this.selectedSection = "yourReceipt";

          // setTimeout(function () {
          //     this.game = true;
          // }, 2000);

          // location.href = "#yourReceipt";
          // document.getElementById('yourReceipt').scrollIntoView();
          // window.location.hash = '#yourReceipt';
          // window.scrollTo(0, 0);
        }
      },
      validateButton() {
        if (this.gameResponse.length > 0) {
          this.answerColor = "blue";
        } else {
          this.answerColor = null;
        }
      },
      verifyAnswer() {
        if (this.gameResponse.length > 0) {
          // console.log("raspunsul meu");
          // console.log(this.gameResponse);
          // console.log("raspuns corect");
          // console.log(
          //   this.formatPrice(
          //     Math.max(this.getSpendWalletBalance, this.productPrice) -
          //     Math.min(this.getSpendWalletBalance, this.productPrice)
          //   )
          // );
          if (
            this.gameResponse ==
            Math.max(this.getSpendWalletBalance, this.productPrice) -
              Math.min(this.getSpendWalletBalance, this.productPrice)
          ) {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = (this.productPrice / 2).toFixed(2);
            // console.log(this.currentPointsNumber);

            const finalBonusPoints =
              Number(this.$store.state.currentOrderSonikPoints) +
              Number(this.currentPointsNumber);

            this.$store.state.currentOrderSonikPoints = finalBonusPoints;

            // console.log(this.$store.state.currentOrderSonikPoints);
            //   let payload = {
            //     points: this.currentPointsNumber,
            //     order_id: this.$store.state.lastOrderId,
            //     game_nr: 2,
            //   };
            //   this.addPoints(payload);
          } else {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = 0;
          }
        } else {
          this.answerColor = null;
          this.gameResponse = "";
        }

        // console.log(this.gameResponse);

        // this.fetchUser();
      },
      closeModal() {
        if (this.fromGoalOrStore == "goal") {
          location.reload();
        }
        this.$emit("closeModal", true);

        this.fetchUser();
      },
    },
  };
</script>
