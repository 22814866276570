<template>
  <div>
    <div class="product-card">
      <div class="div-1">
        <div class="div-2">
          <div class="div-row">
            <div class="div-3">
              <div class="div-4">
                <div class="div-5">
                  <div class="div-6">
                    <div class="div-7">
                      <img
                        :src="resolveImage(defaultPicture)"
                        alt=""
                        class="div-8"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-9">
              <div class="div-5">
                <div class="div-10">
                  <h2 class="div-11">
                    {{ name }}
                  </h2>
                  <!-- <div class="product-text-size">
                          {{product.description}}
                        </div> -->
                  <div class="bottomCardContent">
                    <div class="product-text-price">
                      <span class="price-symbol">&pound;</span>
                      <span class="price-text">
                        {{ formatPrice(price) }}
                      </span>
                    </div>
                    <!-- <div
                      v-if="stars !== null"
                      class="shopProductDetails__right__stars"
                      style="margin-top: 8px;"
                    >
                      <star-rating
                        :rating="Number(stars)"
                        :border-width="3"
                        :read-only="true"
                        :show-rating="false"
                        :star-size="20"
                        inactive-color="#ffffff"
                        active-color="#ffa41d"
                        border-color="#ffa41d"
                        :rounded-corners="true"
                        class="shopProductDetails__right__stars__star"
                        style="display: block !important;width: 100% !important;"
                      >
                      </star-rating>
                    </div> -->
                    <!-- <div style="margin-top: 10px;font-size: 12px;color: #7a615a8a;">
                      {{getProgressBarText()}}
                    </div>
                    <AppProductProgressBar
                      :value="progressValue"
                      :text="getProgressBarText()"
                      :bgProgressColor="progressValue == 100 ? 'linear-gradient(90deg, #56AB2F 0%, #A8E063 100%)' : 'linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%)'"
                      :progressValue="progressValue"
                      style="width: 100%;margin-top: 5px;"
                    /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AppProductProgressBar from "@/components/AppProductProgressBar";
// import AppModal from "@/components/AppModal";
// import AppButton from "@/components/AppButton";
// import AppShopProductDetails from "@/components/AppShopProductDetails";
import { mapGetters } from "vuex";

import config from "../config";

export default {
  components: {
    // AppProductProgressBar,
    // AppModal,
    // AppShopProductDetails,
    // AppButton,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    price: {
      // type: Number,
      // default: 0
    },
    progressValue: {
      // type: Number,
      // default: 0
    },
    stars: {
      type: Number,
      default: 0,
    },
    savingsAdded: {
      // type: String,
      // default: ''
    },
    cashYouMoreNeed: {
      // type: Number,
      // default: 0
    },
    defaultPicture: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default: () => [],
    },
    productId: {
      type: Number,
      default: 0,
    },
    givenStars: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      goalModal: false,
    };
  },
  computed: {
    ...mapGetters(["getSpendWalletBalance"]),
  },
  methods: {
    resolveImage(src) {
      return config.HOST + src;
    },
    openGoalDetailsModal() {
      this.goalModal = true;
    },
    closeGoalDetailsModal() {
      this.goalModal = false;
      this.$store.state.readMore = false;
      this.getSpendWalletBalance;
    },
    getProgressBarText() {
      if (this.progressValue == 100) return "You can buy this";
      else return "You need £" + this.cashYouMoreNeed + " more";
    },
  },
};
</script>

<style lang="scss" scoped>
.div-1 {
  margin-bottom: 8px;
}

.div-2 {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.div-row {
  margin: 0 -8px 0 -4px;
  width: auto;
  padding: 0;
  min-width: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  overflow: visible;
}

.div-4 {
  height: 100%;
  display: block;
  width: calc(0.41667 * (100vw - 8px));
}

.div-5 {
  padding: 0 4px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-6 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  // padding-right: 8px;
  // padding-left: 8px;
  padding: 8px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.div-6:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.03;
  pointer-events: none;
}

.div-7 {
  margin-bottom: 0;
  padding-top: 100%;
  position: relative;
}

.div-8 {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
}

.div-9 {
  display: block;
  width: calc(0.58333 * (100vw - 25px));
  overflow: visible;
}

.div-10 {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 8px;
}

.div-11 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 100;
  -webkit-line-clamp: 3;
  max-height: 72px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
  font-family: "Ember", Arial, sans-serif;
  color: #7a615a;
}
.product-text-size {
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  -webkit-line-clamp: 2;
  max-height: 30px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-family: "Ember", Arial, sans-serif;
}
.product-text-price {
  font-size: 1.6rem;
  margin-top: 25px;
  font-family: "Ember", Arial, sans-serif;
  text-rendering: optimizeLegibility;
  color: #7a615a;
  font-weight: 900;
  // text-align: center;
}
.price-symbol {
  position: relative;
  top: -0.5em;
  right: -5px;
  font-size: 1rem;
  line-height: 1.6;
  font-family: "Ember", Arial, sans-serif;
  text-rendering: optimizeLegibility;
}
.store__container__items__products {
  display: none !important;
}
</style>
