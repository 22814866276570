<template>
  <div>
    <!-- <div style=" width: 100%;">
      <div class="storeItem">
        <div class="storeItem__header">
          <AppProductProgressBar
            :value="progressValue"
            :text="getProgressBarText()"
            bgProgressColor="#36D5F2"
            :progressValue="progressValue"
          />
        </div>
        <div class="storeItem__content">
          <div class="storeItem__content__imagePrice">
            <img
              class="storeItem__content__image"
              :src="resolveImage(defaultPicture)"
            />
            <div class="storeItem__content__details__price">
              £{{ formatPrice(price) }}
            </div>
          </div>
          <div class="storeItem__content__details">
            <div class="storeItem__content__details__title">
              {{ name }}
            </div>
          </div>
        </div>
        <AppButton
          @click="openGoalDetailsModal"
          :mini="true"
          color="red"
          class="shopProductButton"
        >
          Details
        </AppButton>
      </div>
    </div> -->
    <!-- <AppProductProgressBar
      :value="progressValue"
      :text="getProgressBarText()"
      bgProgressColor="linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%)"
      :progressValue="progressValue"
    /> -->
    <!-- <div
      style="margin-top: 10px;font-size: 12px;text-align: center;color: #7a615a8a;"
      :style="[progressValue < 100 ? {'opacity': '1'} : {'opacity': '0'}]"
    >
      {{getProgressBarText()}}
    </div>
    <AppProductProgressBar
      :value="progressValue"
      :text="getProgressBarText()"
      :bgProgressColor="progressValue == 100 ? 'linear-gradient(90deg, #56AB2F 0%, #A8E063 100%)' : 'linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%)'"
      :progressValue="progressValue"
      style="width: 75%;margin-top: 5px;"
      :style="[progressValue < 100 ? {'opacity': '1'} : {'opacity': '0'}]"
    /> -->
    <div
      class="post-module"
      :class="{ faded: progressValue < 100 }"
      @click="openGoalDetailsModal"
    >
      <div class="thumbnail">
        <!-- <div class="date">
          <div class="day">27</div>
          <div class="month">Mar</div>
        </div> -->
        <img :src="resolveImage(defaultPicture)" alt="Picture">
      </div>
      <div class="post-content">
        <!-- <div class="category">£{{ formatPrice(price) }}</div> -->
        <h1 class="title">
          {{ name }}
        </h1>
        <!-- <h2 class="sub_title">The city that never sleeps.</h2>-->
        <!-- <p class="description">{{description}}</p> -->
      </div>
      <div class="bottomCardContent">
        <div class="category">
          <span>&pound;</span>{{ formatPrice(price) }}
        </div>
        <div
          v-if="stars !== null"
          class="shopProductDetails__right__stars"
          style="margin-top: 8px"
        >
          <star-rating
            :rating="Number(stars)"
            :border-width="3"
            :read-only="true"
            :show-rating="false"
            :star-size="20"
            inactive-color="#ffffff"
            active-color="#ffa41d"
            border-color="#ffa41d"
            :rounded-corners="true"
            class="shopProductDetails__right__stars__star"
            style="width: 100% !important; transform: scale(0.8)"
          />
        </div>
        <div
          style="
            margin-top: 10px;
            font-size: 12px;
            text-align: center;
            font-family: Ember;
            color: #7a615a8a;
          "
        >
          {{ getProgressBarText() }}
        </div>
        <AppProductProgressBar
          :value="progressValue"
          :text="getProgressBarText()"
          :bg-progress-color="
            progressValue == 100
              ? 'linear-gradient(90deg, #56AB2F 0%, #A8E063 100%)'
              : 'linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%)'
          "
          :progress-value="progressValue"
          style="width: 75%; margin-top: 5px"
        />
        <!-- <AppButton
          @click="openGoalDetailsModal"
          :mini="true"
          class="shopProductButton"
        >
          Product details
        </AppButton> -->
        <!-- <div style="margin-top: 10px;font-size: 12px;">{{getProgressBarText()}}</div>
        <AppProductProgressBar
          :value="progressValue"
          :text="getProgressBarText()"
          bgProgressColor="linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%)"
          :progressValue="progressValue"
          style="width: 75%;margin-top: 5px;margin-bottom: -10px;"
        /> -->
      </div>
    </div>

    <AppModal
      :is-open="goalModal"
      @close="closeGoalDetailsModal"
    >
      <AppShopProductDetails
        selected-section="details"
        :name="name"
        :product-id="productId"
        :description="description"
        :price="price"
        :stars="stars"
        :savings-added="savingsAdded"
        :default-picture="defaultPicture"
        :images="images"
        :cash-you-more-need="cashYouMoreNeed"
        :given-stars="givenStars"
        @closeModal="closeGoalDetailsModal"
      />
    </AppModal>
  </div>
</template>

<script>
import AppProductProgressBar from "../components/AppProductProgressBar";
import AppModal from "../components/AppModal";
// import AppButton from "@/components/AppButton";
import AppShopProductDetails from "../components/AppShopProductDetails";
import { mapGetters } from "vuex";

import config from "../config";

export default {
  components: {
    AppProductProgressBar,
    AppModal,
    AppShopProductDetails,
    // AppButton,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    price: {
      // type: Number,
      // default: 0
    },
    progressValue: {
      // type: Number,
      // default: 0
    },
    stars: {
      type: Number,
      default: 0,
    },
    savingsAdded: {
      // type: String,
      // default: ''
    },
    cashYouMoreNeed: {
      // type: Number,
      // default: 0
    },
    defaultPicture: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default: () => [],
    },
    productId: {
      type: Number,
      default: 0,
    },
    givenStars: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      goalModal: false,
    };
  },
  computed: {
    ...mapGetters(["getSpendWalletBalance"]),
  },
  methods: {
    resolveImage(src) {
      return config.HOST + src;
    },
    openGoalDetailsModal() {
      this.goalModal = true;
    },
    closeGoalDetailsModal() {
      this.goalModal = false;
      this.$store.state.readMore = false;
      this.getSpendWalletBalance;
    },
    getProgressBarText() {
      if (this.progressValue == 100) return "You can buy this";
      else return "You need £" + this.cashYouMoreNeed + " more";
    },
  },
};
</script>

<style lang="scss" scoped>
.post-module {
  position: relative;
  font-family: "Ember", sans-serif;
  font-weight: 100 !important;
  z-index: 1;
  display: block;
  background: #ffffff;
  border: 1px solid #eee;
  color: #7a615a;
  border-radius: 30px;
  min-width: 100%;
  height: 425px;
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.1),
    0 5px 5px -5px rgba(0, 0, 0, 0.04), 0 0 20px rgba(0, 0, 0, 0.05) inset;
  -webkit-transition: all 0.1s linear 0s;
  -moz-transition: all 0.1s linear 0s;
  -ms-transition: all 0.1s linear 0s;
  -o-transition: all 0.1s linear 0s;
  transition: all 0.1s linear 0s;
}
.post-module:hover,
.hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.post-module:hover .thumbnail img,
.hover .thumbnail img {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  transform: scale(1.03);
}
.post-module .thumbnail {
  height: 200px;
  overflow: hidden;
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #ff4f79;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: contain;
  -webkit-transition: all 0.1s linear 0s;
  -moz-transition: all 0.1s linear 0s;
  -ms-transition: all 0.1s linear 0s;
  -o-transition: all 0.1s linear 0s;
  transition: all 0.1s linear 0s;
}
.post-module .post-content {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 20px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.1s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.1s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.1s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.1s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}
.post-module .post-content .category {
  padding: 10px 15px;
  color: #ff4f79;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.post-module .category {
  color: #ff4f79;
  font-size: 35px;

  font-weight: 100;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(90deg, #bc4e9c 0%, #f80759 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  span {
    font-size: 18px;
  }
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #7a615a;
  font-size: 16px;
  font-family: "Ember", sans-serif;
  font-weight: 100;
  display: -webkit-box;
  height: 35px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #ff4f79;
  font-size: 20px;
  font-weight: 100;
}
.post-module .post-content .description {
  color: #888888;
  font-size: 10px;
  font-weight: 100;
  line-height: 1.4em;
  margin-top: 10px;
  display: -webkit-box;
  height: 40px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}
.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}
// .faded {
//   opacity: 0.6;
// }
.bottomCardContent {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
</style>
