<template>
  <div :class="'appModal ' + (isOpen ? 'appModal-open' : '')">
    <div class="appModal__content">
      <div class="modalHeader">
        <div style="display: flex;flex-direction: column;">
          <h1 class="modalHeader__title">
            {{ title }}
          </h1>
          <div class="modalHeader__subtitle">
            <small>{{ subtitle }}</small>
          </div>
        </div>
        <div
          class="appModal__close"
          @click="close"
        />
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.appModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  min-height: 10vh;
  overflow: auto;
  background-color: rgba(black, 0.2);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999 !important;
  backdrop-filter: blur(15px);
  &__content {
    max-height: 80vh;
    width: 770px;
    background-color: white;
    border-radius: 50px;
    padding: 30px;
    box-sizing: border-box;
    overflow: auto;
    // padding-left: 40px;
    // padding-right: 40px;
    position: relative;
  }

  &__close,
  &__close__content {
    position: sticky;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    margin: 0 0 0 auto;
    border-radius: 12px;
    background-color: #ff4f79;
    background-image: url("../assets/images/cancel.png");
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: center;
    z-index: 10;
    cursor: pointer;

    &__content {
      display: none;
    }
  }

  &-open {
    display: flex;
    z-index: 4;
  }
}
.appModal__content::-webkit-scrollbar {
  border-radius: 50px;
  width: 10px;
  background-color: transparent;
  left: -20px;
}

.appModal__content::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  border-radius: 100px;
}

@media screen and (max-width: 820px) {
  .appModal__content::-webkit-scrollbar {
    display: none;
  }

  .appModal {
    z-index: 5;

    &__content {
      max-height: 100vh;
      // width: 100vw;
      // height: 100vh;
      width: 90%;
      height: 95%;
      border-radius: 20px;
      padding: 120px 20px;
    }

    &__close {
      // display: none;
      background-size: 16px;
      width: 30px;
      height: 30px;
      top: 0px !important;
      right: 0px !important;

      &__content {
        display: block;
        // top: -60px;
        // left: 50%;
        transform: translateX(-20px);
      }
    }
  }
}

@media screen and (max-height: 650px) {
  .appModal {
    &__content {
      overflow-y: scroll;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    margin: 0;
    font-size: 20px;
    color: #7a615a;
    
    font-weight: 100;
  }
  &__subtitle {
    color: #7a615a;
  }
}
</style>
