<template>
  <div
    id="storeSwipe"
    class="container"
    :class="{ noSwipe: getSwipeProducts.length == 0 }"
  >
    <div
      v-if="getSwipeProducts.length == 0"
      class="noSwipeProducts"
    >
      <img
        src="../assets/images/neutral.gif"
        style="width: 50px; height: 50px; border-radius: 50%"
        alt=""
      >
      <div class="text">
        There are no products
      </div>
    </div>
    <div v-else>
      <div
        v-if="current"
        style="z-index: 3"
        class="card card--one"
        :class="{ transition: isVisible }"
      >
        <img
          alt="Product image"
          class="swipeSection__products__image"
          :src="resolveImage(current.default_picture_link)"
        >

        <div>
          <div class="swipeSection__products__details">
            <div class="swipeSection__products__details__title">
              {{ current.name }}
            </div>
            <!-- <div class="swipeSection__products__details__description">
              <span v-if="!$store.state.readMore"
                >{{ current.description.slice(0, 100) }}
              </span>
              <a
                v-if="
                  !$store.state.readMore && current.description.length > 100
                "
                @click="activateReadMore"
                href="#"
                class="readMore"
              >
                Read more...
              </a>
              <span
                v-if="$store.state.readMore"
                v-html="current.description"
              ></span>
            </div> -->
          </div>
          <div class="swipeSection__products__details__price">
            <div class="priceText">
              £{{ formatPrice(current.price) }}
            </div>
            <star-rating
              :rating="current.star"
              :border-width="3"
              :read-only="true"
              :show-rating="false"
              :star-size="20"
              inactive-color="#ffffff"
              active-color="#FF4F79"
              border-color="#FF4F79"
              :rounded-corners="true"
              class=""
            />
          </div>
        </div>
      </div>

      <div
        v-if="getSwipeProducts.length > 0"
        class="footer"
      >
        <div
          class="btn btn--decline"
          @click="skip"
        />
        <AppButton
          text="Buy"
          :color="validatePrice"
          :mini="true"
          class="buy"
          :disabled="validatePrice == ''"
          @click="openBuyModal"
        />
        <div
          class="btn btn--like"
          @click="match"
        />
      </div>
      <AppModal
        :is-open="buyModal"
        @close="closeBuyModal"
      >
        <AppBonusPoints
          v-if="swipeSelectedSection == 'bonusPoints'"
          :name="current.name"
          :description="current.description"
          :product-price="current.price"
          :default-picture="current.default_picture_link"
          @openCheckoutGame="openCheckoutGame"
        />
        <AppCheckoutGame
          v-if="swipeSelectedSection == 'checkoutGame'"
          rom-goal-or-store="shop"
          :product-id="productId"
          :product-price="current.price"
          :description="description"
          :default-picture="defaultPicture"
          selected-section="gameCheck"
          @openCheckout="openCheckout"
        />
        <AppCheckout
          v-if="swipeSelectedSection == 'checkout'"
          :name="current.name"
          from-goal-or-store="shop"
          :product-id="current.id"
          :product-price="current.price"
          :description="current.description"
          :default-picture="current.default_picture_link"
          @closeModal="closeBuyModal"
        />
      </AppModal>
    </div>
  </div>
</template>
<script>
// import { Vue2InteractDraggable, InteractEventBus } from "vue2-interact";
import AppButton from "../components/AppButton";
import AppModal from "../components/AppModal";
import AppBonusPoints from "../components/AppBonusPoints";
import AppCheckout from "../components/AppCheckout";
import AppCheckoutGame from "../components/AppCheckoutGame";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import config from "../config";

// const EVENTS = {
//   MATCH: "match",
//   SKIP: "skip",
//   REJECT: "reject",
// };
export default {
  components: {
    AppButton,
    AppModal,
    // Vue2InteractDraggable,
    AppCheckoutGame,
    AppBonusPoints,
    AppCheckout,
  },
  data() {
    return {
      isVisible: false,
      index: 0,
      // interactEventBus: {
      //   draggedRight: EVENTS.MATCH,
      //   draggedLeft: EVENTS.REJECT,
      //   draggedUp: EVENTS.SKIP,
      // },
      buyModal: false,
      swipeSelectedSection: "bonusPoints",
    };
  },
  computed: {
    ...mapGetters(["getSwipeProducts"]),
    current() {
      return this.getSwipeProducts[this.index];
    },
    next() {
      return this.getSwipeProducts[this.index + 1];
    },
    validatePrice() {
      if (this.$store.state.spendWallet.current_balance >= this.current.price) {
        return "blue";
      } else {
        return "";
      }
    },
  },
  created() {
    this.fetchSwipeProducts(),
      (this.currentItemsNumber = this.getSwipeProducts.length);
    //   console.log("products");
    // console.log(this.$store.state.spendWallet.current_balance);
  },
  methods: {
    ...mapActions(["fetchSwipeProducts", "productPreferencesAdd"]),
    resolveImage(src) {
      return config.HOST + src;
    },
    activateReadMore() {
      this.$store.state.readMore = true;
    },
    match() {
      this.isVisible = true;
      // InteractEventBus.$emit(EVENTS.MATCH);
      let payload = {
        product_id: this.current.id,
        users_like: 1,
      };
      this.productPreferencesAdd(payload);
      setTimeout(() => (this.isVisible = false), 1000);
    },
    buy() {
      // InteractEventBus.$emit(EVENTS.REJECT);
    },
    skip() {
      this.isVisible = true;
      // InteractEventBus.$emit(EVENTS.SKIP);
      let payload = {
        product_id: this.current.id,
        users_like: 0,
      };
      this.productPreferencesAdd(payload);
      setTimeout(() => (this.isVisible = false), 1000);
    },
    emitAndNext(event) {
      this.$emit(event, this.index);
      setTimeout(() => (this.isVisible = false), 2000);
      setTimeout(() => {
        this.index++;
        this.isVisible = true;
      }, 200);
    },
    openBuyModal() {
      this.buyModal = true;
    },
    closeBuyModal() {
      this.buyModal = false;
    },
    openCheckout() {
      this.swipeSelectedSection = "checkout";
    },
    openCheckoutGame() {
      this.swipeSelectedSection = "checkoutGame";
    },
  },
};
</script>

<style lang="scss" scoped>
.shopProductDetails__right__stars__star {
  width: 50px;
  padding: 0px;
}
.container {
  width: 100%;
  height: auto !important;
  overflow-y: none;
  flex-direction: column;
}
.footer {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}
.btn {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:active {
    transform: translateY(4px);
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::before {
      content: "";
    }
  }
  &--like {
    width: 100px;
    height: 100px;
    background-image: url("../assets/images/swipeRight.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  &--decline {
    width: 100px;
    height: 100px;
    background-image: url("../assets/images/swipeLeft.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.buy {
  width: 170px;
  margin-top: -13px;
  height: 60px;
}
.flex {
  display: flex;
  &--center {
    align-items: center;
    justify-content: center;
  }
}
.rounded-borders {
  border-radius: 50px;
}
.card {
  width: 90%;
  margin: 30px auto;
  margin-top: 5px;
  height: 400px;
  padding: 30px;
  color: white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 40px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 40px;
  }
  &--one {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  }
  &--two {
    transform: translateY(-45%, 10px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  }
  &--three {
    transform: translateY(-45%, 15px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  }
  .text {
    position: absolute;
    bottom: 0;
    width: 100%;
    // background:black;
    // background:rgba(0,0,0,0.5);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    text-indent: 20px;
    span {
      font-weight: normal;
    }
  }
}
.transition {
  -webkit-animation: slide-out-blurred-top 0.45s
    cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06)
    both;
}

@-webkit-keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.swipeSection {
  height: 100%;

  &__products {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__image {
      width: 150px;
      height: 150px;
    }

    &__details {
      width: 100%;
      // margin-left: 30px;
      // margin-right: 10px;
      // margin-top: 70px;

      &__title {
        font-size: 20px;
        line-height: 24px;
        color: #7a615a;
        margin-bottom: 20px;
      }
      &__description {
        color: #7a615a;
        font-size: 16px;
        line-height: 20px;
      }
      &__price {
        font-size: 40px;
        line-height: 24px;
        color: #ff4f79;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        bottom: 30px;
        right: 30px;
      }

      &__stars {
        width: 100%;
        margin-top: 10px;

        &__star {
          width: 100% !important;
          // margin-top: 20px;
        }
      }
    }
  }
}

.priceText {
  margin-bottom: 10px;
}

.noSwipeProducts {
  color: #7a615a;
  text-align: center;

  vertical-align: middle;
}

.noSwipe {
  // height: 100px !important;
  background: #f8f5ed;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 100px;
  padding: 20px 0;
  .text {
    margin-top: 10px;
    font-weight: 200;
  }
}
</style>
