<template>
  <div class="cardWallet">
    <div>
      <div class="holder-desc">
        Item cost
      </div>
      <div class="holder-price">
        <span class="poundSign">&pound;</span>{{ balance }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balance: {
      // type: Number,
      // default: 0,
    },
  },
};
</script>

<style lang="scss">
.cardWallet {
  width: 80%;
  height: 130px;
  border-radius: 13px;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--yellow);
}
.holder-price {
  font-size: 30px;
  font-weight: 700;
}
.holder-desc {
  font-size: 14px;
  font-weight: 700;
  // letter-spacing: -0.3px;
}
.poundSign {
  font-size: 18px;
}
</style>
