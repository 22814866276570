<template>
  <div>
    <div
      v-if="selectedSection == 'details' && selectedSectionLocal == 'details'"
      class="shopProductDetails"
    >
      <div class="shopProductDetails__left">
        <img
          class="shopProductDetails__left__image"
          :src="resolveImage(this.showImage)"
        >
        <div class="shopProductDetails__left__images">
          <img
            v-for="(image, index) in images"
            :key="index"
            class="shopProductDetails__left__images__small"
            :src="resolveImage(image)"
            :style="{ 'border-color': imagesBorders[index] }"
            @click="switchImage(image, index)"
          >
        </div>
      </div>
      <div class="shopProductDetails__right">
        <div class="shopProductDetails__right__title">
          {{ name }}
        </div>
        <div class="shopProductDetails__right__info">
          <span
            v-if="!$store.state.readMore"
          >{{ description.slice(0, 100) }}
          </span>
          <span
            v-if="$store.state.readMore"
            v-html="description"
          />
          <span
            v-if="!$store.state.readMore && description.length > 100"
            class="readMore"
            @click="activateReadMore"
          >
            Read more...
          </span>
          <span
            v-else-if="$store.state.readMore"
            class="readMore"
            @click="activateReadMore"
          >
            Read less
          </span>
        </div>
        <div class="shopProductDetails__right__miniGrid">
          <div
            class="shopProductDetails__right__miniGrid__price"
            :style="{ color: priceColor() }"
          >
            £{{ formatPrice(price) }}
          </div>
          <div
            v-if="stars !== null"
            class="shopProductDetails__right__stars"
          >
            <star-rating
              :rating="stars"
              :border-width="3"
              :read-only="true"
              :show-rating="false"
              :star-size="20"
              inactive-color="#ffffff"
              active-color="#ffa41d"
              border-color="#ffa41d"
              :rounded-corners="true"
              class="shopProductDetails__right__stars__star"
            />
          </div>

          <!-- <div v-else class="shopProductDetails__right__info rating-left">
                      The rating given by you
                    </div> -->
          <!-- <star-rating
                      v-if="givenStars != 0"
                      :border-width="4"
                      inactive-color="#ffffff"
                      active-color="#FF4F79"
                      border-color="#FF4F79"
                      :rounded-corners="true"
                      :show-rating="false"
                      :star-size="30"
                      class="shopProductDetails__right__stars__star"
                      :rating="givenStars"
                      :read-only="true"
                      @rating-selected="giveRating"
                    >
                    </star-rating> -->
          <!-- <star-rating
                      :border-width="4"
                      inactive-color="#ffffff"
                      active-color="#FF4F79"
                      border-color="#FF4F79"
                      :rounded-corners="true"
                      :show-rating="false"
                      :star-size="30"
                      class="shopProductDetails__right__stars__star"
                      @rating-selected="giveRating"
                    >
                    </star-rating> -->
        </div>
        <div
          v-if="givenStars == 0"
          class="shopProductDetails__right__info leave-rating"
        >
          Leave your rating
          <star-rating
            :border-width="4"
            inactive-color="#ffffff"
            active-color="#ffa41d"
            border-color="#ffa41d"
            :rounded-corners="true"
            :show-rating="false"
            :star-size="30"
            class="shopProductDetails__right__stars__star"
            @rating-selected="giveRating"
          />
        </div>

        <!-- <div class="shopProductDetails__right__ocassions">
                  <img
                    v-for="(ocassion, index) in getOcassions"
                    :key="index"
                    :src="ocassion.image_link_encode"
                    class="shopProductDetails__right__ocassions__icon"
                    @click="selectOcassion(ocassion.id)"
                    :class="{
                      shopProductDetails__right__ocassions__border:
                        ocassion.id == selectedOccasionId,
                    }"
                  />
                </div> -->

        <AppErrorMessage :message="error" />

        <div class="shopProductDetails__right__button">
          <AppButton
            text="Add to Goals"
            color="red"
            :mini="true"
            class="shopProductDetails__right__buttons"
            @click="addProductToGoals"
          />
          <AppButton
            text="Add to Wishlist"
            color="red"
            :mini="true"
            class="shopProductDetails__right__buttons"
            @click="showOcassions"
          />
          <!-- <AppButton
                      text="Add to Wishlist"
                      :color="selectedOccasionId ? 'red' : ''"
                      :mini="true"
                      class="shopProductDetails__right__buttons"
                      @click="addProductToWishlist"
                    >
                    </AppButton> -->
        </div>
        <div
          v-if="ocassionsShow"
          class="ocassionsDiv"
        >
          <div class="ocassionsArrow" />
          <div
            class="closeOcassions"
            @click="ocassionsShow = false"
          />
          <h1 class="ocassionTitle">
            Choose an ocassion
          </h1>
          <div class="shopProductDetails__right__ocassions">
            <div
              v-for="(occasion, index) in getOcassions"
              :key="index"
              class="ocassion_item"
            >
              <div
                class="ocassion_holder"
                :class="{
                  appWishlistDetails__right__ocassions__border:
                    occasion.id == selectedOccasionId
                }"
                @click="selectOcassion(occasion.id)"
              >
                <img :src="occasion.image_link_encode" alt="Occasion">
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="cashYouMoreNeed == 0 || cashYouMoreNeed == null"
          class="shopProductDetails__right__button"
        >
          <AppButton
            text="Buy"
            color="blue"
            :mini="true"
            @click="buyProduct()"
          />
        </div>
        <div
          v-else
          class="shopProductDetails__right__button"
        >
          <AppButton
            text="Buy"
            color=""
            :mini="true"
          />
        </div>
      </div>
    </div>
    <AppBonusPoints
      v-if="
        selectedSection == 'bonusPoints' ||
          selectedSectionLocal == 'bonusPoints'
      "
      :name="name"
      :product-price="price"
      :description="description"
      :default-picture="defaultPicture"
      @openCheckoutGame="openCheckoutGame"
    />
    <AppCheckoutGame
      v-if="
        selectedSection == 'checkoutGame' ||
          selectedSectionLocal == 'checkoutGame'
      "
      rom-goal-or-store="shop"
      :product-id="productId"
      :product-price="price"
      :description="description"
      :default-picture="defaultPicture"
      selected-section="gameCheck"
      @openCheckout="openCheckout"
    />
    <AppCheckout
      v-if="selectedSection == 'checkout' || selectedSectionLocal == 'checkout'"
      :name="name"
      from-goal-or-store="shop"
      :product-id="productId"
      :product-price="price"
      :description="description"
      :default-picture="defaultPicture"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import AppButton from "../components/AppButton";
import AppErrorMessage from "../components/AppErrorMessage";
import AppBonusPoints from "../components/AppBonusPoints";
import AppCheckout from "../components/AppCheckout";
import AppCheckoutGame from "../components/AppCheckoutGame";
import { mapActions, mapGetters } from "vuex";
import config from "../config";

export default {
  components: {
    AppButton,
    AppErrorMessage,
    AppBonusPoints,
    AppCheckout,
    AppCheckoutGame
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    price: {
      // type: Number,
      // default: 0
    },
    progressValue: {
      // type: Number,
      // default: 0
    },
    stars: {
      type: Number,
      default: 0
    },
    savingsAdded: {
      // type: String,
      // default: ''
    },
    defaultPicture: {
      type: String,
      default: ""
    },
    images: {
      type: Array,
      default: () => []
    },
    cashYouMoreNeed: {
      // type: Number,
      // default: 0
    },
    productId: {
      type: Number,
      default: 0
    },
    selectedSection: {
      type: String,
      default: "details"
    },
    givenStars: {
      type: Number,
      default: 0
    },
    clearOccasions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      error: "",
      readMoreActivated: false,
      ocassions: [],
      selectedOccasionId: null,
      showImage: this.defaultPicture,
      imagesBorders: ["#36D5F2", "#E5E5E5", "#E5E5E5", "#E5E5E5"],
      selectedSectionLocal: "details",
      ocassionsShow: false
    };
  },
  methods: {
    ...mapActions(["addGoal", "setRating", "addWishlist"]),
    resolveImage(src) {
      return config.HOST + src;
    },
    activateReadMore() {
      this.$store.state.readMore = !this.$store.state.readMore;
    },
    switchImage(imgsrc, index) {
      this.showImage = imgsrc;

      this.imagesBorders[0] = "#E5E5E5";
      this.imagesBorders[1] = "#E5E5E5";
      this.imagesBorders[2] = "#E5E5E5";
      this.imagesBorders[3] = "#E5E5E5";
      this.imagesBorders[index] = "#36D5F2";
    },
    showOcassions() {
      this.ocassionsShow = !this.ocassionsShow;
    },
    selectOcassion(ocassionId) {
      this.selectedOccasionId = ocassionId;
      // console.log("ocassion id");
      // console.log(ocassionId);
      // console.log("ocassion id");

      if (this.selectedOccasionId) {
        const details = {
          product_id: this.productId,
          occasion_id: this.selectedOccasionId,
          name: "Add wishlist from shop section"
        };
        this.addWishlist(details);
        this.error = "";
        this.selectedOccasionId = null;
        this.$emit("closeModal", true);
      } else {
        this.error = "You must select an occasion!";
      }
    },
    priceColor() {
      if (
        this.price <= this.getSpendWalletBalance &&
        (this.cashYouMoreNeed == null || this.cashYouMoreNeed == 0)
      )
        return "#36D5F2";
      return "#DDDDDD";
    },
    addProductToGoals() {
      // if (this.selectedOccasionId) {
      const details = {
        product_id: this.productId,
        target_sum: this.price,
        // occasion_id: this.selectedOccasionId,
        name: "Add goal from shop section"
      };
      this.addGoal(details);
      this.error = "";
      this.selectedOccasionId = null;
      this.$emit("closeModal", true);
      // } else {
      //   this.error = "You must select an occasion!";
      // }
    },
    addProductToWishlist() {
      if (this.selectedOccasionId) {
        const details = {
          product_id: this.productId,
          occasion_id: this.selectedOccasionId,
          name: "Add wishlist from shop section"
        };
        this.addWishlist(details);
        this.error = "";
        this.selectedOccasionId = null;
        this.$emit("closeModal", true);
      } else {
        this.error = "You must select an occasion!";
      }
    },
    buyProduct() {
      this.selectedSectionLocal = "bonusPoints";
    },
    openCheckoutGame() {
      this.selectedSectionLocal = "checkoutGame";
    },
    openCheckout() {
      this.selectedSectionLocal = "checkout";
    },
    closeModal() {
      this.selectedOccasionId = null;
      this.$emit("closeModal", true);
    },
    giveRating(stars) {
      const details = {
        memberId: this.productId,
        stars: stars,
        type: "product"
      };
      // if(stars >= 1 && stars <= 5)
      this.setRating(details);
    }
  },
  computed: {
    ...mapGetters(["getOcassions", "getSpendWalletBalance"])
  },
  created() {
    this.ocassions = this.getOcassions;
    // console.log(this.stars);
  }
};
</script>

<style lang="scss">
.shopProductDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__left {
    width: 100%;

    &__image {
      width: 100%;
      height: auto;
    }

    &__images {
      padding: 10px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      img {
        height: 50px !important;
      }

      &__small {
        width: 100%;
        height: auto;
        border: 1px solid #dddddd;
        border-radius: 10px;
      }
    }
  }

  &__right {
    width: 100%;
    // padding: 30px;
    padding-left: 20px;

    &__title {
      padding: 10px;

      font-size: 20px;
      line-height: 25px;
      color: #7a615a;
    }

    &__info {
      padding: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #7a615a;
    }

    &__miniGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 30px;

      &__price {
        padding: 10px;

        font-size: 38px;
        line-height: 30px;
        // color: #7A615A;
      }
    }

    &__stars {
      width: 100%;
      display: flex;

      &__star {
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
      }
    }

    &__ocassions {
      // height: 40px;
      padding: 10px;
      border-radius: 50px;
      width: 100%;
      display: grid;
      margin-top: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      // background: #f8f5ed;
      // background: linear-gradient(181.4deg, #2ccbe8 35.62%, #6cf9ff 103.95%);
      // box-shadow: 0 0 0 2px #36d5f2;

      &__icon {
        height: 20px;
        width: 20px;
        padding: 10px;
        border: 2px solid #fff !important;
        border-radius: 50px;
        margin: 5px;
      }
    }

    &__button {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }

    &__buttons {
      width: 45%;
      margin-top: 10px;
      margin: 5px;
      padding: 5px !important;
    }
  }
}

.ocassion_holder {
  // background: linear-gradient(360deg, #2ccbe8 35.62%, #6cf9ff 103.95%);
  border: none !important;
  // box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 2px 0px rgba(0, 0, 0, 0.2),
  //   0 0 0 0 #23bcd8, 0 0 2px 5px rgba(255, 255, 255, 0.2);
  // img {
  //   filter: invert(1);
  // }
  background: white;
}

// .shopProductDetails__right__ocassions__border {
//   // border: 2px solid #ff4f79 !important;
//   background: #222 !important;
//   padding: 100px;
// }
@media screen and (max-width: 850px) {
  .shopProductDetails {
    display: grid;
    grid-template-columns: 1fr;
    // padding: 0px;
    width: auto;
    margin-right: auto;

    &__right {
      margin-left: 0px;
      padding: 0px;
    }
  }
}

.shopProductDetails__right__ocassions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.shopProductDetails__right__buttons {
  font-size: 16px;
}

.ocassionTitle {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
  color: #7a615a;
}

.leave-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (max-width: 820px) {
  .leave-rating,
  .rating-left {
    margin-top: 30px;
    font-size: 18px !important;
    text-align: center;
  }
  .leave-rating .vue-star-rating {
    margin-top: 5px;
  }
  // .leave-rating .vue-star-rating-star svg {
  //   width: 25px !important;
  //   height: 25px !important;
  // }
  .shopProductDetails__left__images {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    img {
      height: 60px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .ocassionTitle {
    font-size: 16px;
  }

  .shopProductDetails__right__ocassions {
    display: flex;
    justify-content: space-between;
  }
  .shopProductDetails__right__ocassions__icon {
    border: 2px solid #dddddd;
    border-radius: 100px;
    width: 40px;
    height: 40px;
  }
  .shopProductDetails__right__buttons {
    border-radius: 10px;
    font-size: 16px;
  }
}

.shopProductDetails__left__images img {
  height: 50px !important;
  object-fit: contain !important;
}

.shopProductDetails__left__image {
  height: 50vh;
  object-fit: contain;
}

.shopProductDetails__right__buttons {
  height: 50px !important;
  letter-spacing: 0.5px;
  width: 50% !important;
}
</style>
