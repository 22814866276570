<template>
  <div class="viewFix">
    <div class="store layoutFix">
      <div class="store__container">
        <div v-if="section == 'swipe'">
          <!-- <div class="store__container__items">
                    <div class="store__container__items__title">
                        Let's Swipe!
                    </div>

                </div> -->
          <AppStoreSwipe />
        </div>

        <div
          v-else
          class="store__container__items"
        >
          <div class="store__container__items__top">
            <div class="store__container__items__top__title">
              Gifts Store
              <div class="gifts-store-subtitle">
                Buy for yourself and for the loved ones
              </div>
            </div>
            <div class="store__container__items__top__search">
              <!-- <RouterLink to="/shop">
                <h1 class="takemebacktitle">Take me back</h1>
                <p class="takemebackdesc">go back to my store</p>
              </RouterLink> -->
              <RouterLink
              replace
                to="/shop"
                class="sendGift"
              >
                <div class="sendGift__text">
                  <h1 class="takemebacktitle">
                    Take me back
                  </h1>
                  <p class="takemebackdesc">
                    go back to my store
                  </p>
                </div>
                <img
                  src="../../assets/images/back.svg"
                  style="width: 35px;height: 35px;margin-left: 10px;margin-top: -5px;"
                  class="giftShopBack"
                >
              </RouterLink>
              <!-- <input
                type="text"
                placeholder="Search toys"
                v-model="search"
                class="store__container__items__top__search__input"
                @keyup="filterItemsByKey()"
              /> -->

              <div
                id="search"
                class="mobileSearch"
                style="display: none !important;"
              >
                <input
                  id="input"
                  v-model="search"
                  placeholder="Search..."
                  @keyup="filterItemsByKey()"
                >
                <span id="button">
                  <svg
                    version="1.1"
                    class="fa-search"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 124.524 124.524"
                    style="enable-background:new 0 0 124.524 124.524;"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        fill="#7a615a59"
                        d="M51,102.05c10.5,0,20.2-3.2,28.3-8.6l29.3,29.3c2.301,2.3,6.101,2.3,8.5,0l5.7-5.7c2.3-2.3,2.3-6.1,0-8.5L93.4,79.35
		c5.399-8.1,8.6-17.8,8.6-28.3c0-28.1-22.9-51-51-51c-28.1,0-51,22.9-51,51C0,79.149,22.8,102.05,51,102.05z M51,20.05
		c17.1,0,31,13.9,31,31c0,17.1-13.9,31-31,31c-17.1,0-31-13.9-31-31C20,33.95,33.9,20.05,51,20.05z"
                      />
                    </g>
                  </svg>
                </span>
                <div class="spinner">
                  <i class="fa fa-spinner" />
                </div>
              </div>
            </div>
          </div>
          <div class="store-categories-container">
            <div class="store__container__items__categories">
              <AppCategoryItem
                v-for="(category, index) in takeGiftsCategories"
                :key="index"
                :name="category.name"
                :icon-src="category.image_link"
                :bg-color="category.bgColor"
                class="store__container__items__categories__item"
                :selected="isSelected(category.id)"
                @click.native="selectCategory(category.id)"
              />
            </div>
          </div>
          <AppErrorMessage
            v-if="error != ''"
            :message="error"
          />
          <div
            class="store__container__items__products"
            style="overflow: unset;background: white;display: grid;grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); grid-gap: 20px;"
          >
            <AppStoreItem
              v-for="(product, index) in takeGiftsCategories[
                selectedProductCurrentIndex
              ].products.filter(product => (product.price > 0 && product.price < this.range) ? product : '')"
              :key="index"
              :name="product.name"
              :product-id="product.id"
              :price="Number(product.price).toFixed(2)"
              :default-picture="product.default_picture_link"
              :images="getProductImages(product)"
              :progress-value="Number(product.percent).toFixed(2)"
              :description="product.description"
              :stars="product.star"
              :cash-you-more-need="Number(product.cash_u_need_more).toFixed(2)"
              :given-stars="product.myStar"
              :has-button="true"
            />
            <div style="height: 20px;" />
          </div>

          <div
            v-for="commentIndex in productsToShow"
            :key="commentIndex-1"
          >
            <div
              v-if="commentIndex-1 < takeGiftsCategories[
                selectedProductCurrentIndex
              ].products.length"
            >
              <MobileStoreItem
                :name="takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].name"
                :product-id="takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].id"
                :price="Number(takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].price).toFixed(2)"
                :default-picture="takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].default_picture_link"
                :images="getProductImages(takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1])"
                :progress-value="Number(takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].percent).toFixed(2)"
                :description="takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].description"
                :stars="takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].star"
                :cash-you-more-need="Number(takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].cash_u_need_more).toFixed(2)"
                :given-stars="takeGiftsCategories[
                  selectedProductCurrentIndex
                ].products[commentIndex-1].myStar"
                :has-button="true"
                class="mobile-store-item"
              />
            </div>
          </div>
          <div
            v-if="productsToShow < takeGiftsCategories[
              selectedProductCurrentIndex
            ].products.length || takeGiftsCategories[
              selectedProductCurrentIndex
            ].products.length > productsToShow"
          >
            <AppButton
              text="Show more products"
              color="red"
              :mini="true"
              style="margin: 40px auto 20px auto !important;height: 50px !important;width: 70% !important;display: block !important;"
              @click="productsToShow += 5"
            />
          </div>

          <!-- <MobileStoreItem
            v-for="(product, index) in takeGiftsCategories[
                selectedProductCurrentIndex
              ].products"
            :key="index"
            :name="product.name"
            :productId="product.id"
            :price="Number(product.price).toFixed(2)"
            :defaultPicture="product.default_picture_link"
            :images="getProductImages(product)"
            :progressValue="Number(product.percent).toFixed(2)"
            :description="product.description"
            :stars="product.star"
            :cashYouMoreNeed="Number(product.cash_u_need_more).toFixed(2)"
            :givenStars="product.myStar"
            :has-button="true"
            class="mobile-store-item"
          /> -->
        </div>
      </div>
    </div>
    <div class="sidebarFix">
      <div class="store__sidebar">
        <!-- <div class="store__sidebar__spendWallet">
          <AppSpendWallet
            :wishlist="true"
            :balance="formatPrice(getSpendWalletBalance)"
            class="goals__sidebar__wallet mobile-wallet-shop"
          />
        </div> -->
        <AppDashboardWallet
          class="desktop-wallet"
          wallet="Spend"
          :balance="formatPrice(getSpendWalletBalance)"
          :dashboard="true"
        />
        <AppDashboardWallet
          class="desktop-wallet"
          wallet="Save"
          :balance="formatPrice(getSaveWalletBalance)"
          :dashboard="true"
        />

        <div
          id="search"
          class="desktopSearch"
        >
          <input
            id="input"
            v-model="search"
            placeholder="Search..."
            @keyup="filterItemsByKey()"
          >
          <span id="button">
            <svg
              version="1.1"
              class="fa-search"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 124.524 124.524"
              style="enable-background:new 0 0 124.524 124.524;"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#7a615a59"
                  d="M51,102.05c10.5,0,20.2-3.2,28.3-8.6l29.3,29.3c2.301,2.3,6.101,2.3,8.5,0l5.7-5.7c2.3-2.3,2.3-6.1,0-8.5L93.4,79.35
		c5.399-8.1,8.6-17.8,8.6-28.3c0-28.1-22.9-51-51-51c-28.1,0-51,22.9-51,51C0,79.149,22.8,102.05,51,102.05z M51,20.05
		c17.1,0,31,13.9,31,31c0,17.1-13.9,31-31,31c-17.1,0-31-13.9-31-31C20,33.95,33.9,20.05,51,20.05z"
                />
              </g>
            </svg>
          </span>
          <div class="spinner">
            <i class="fa fa-spinner" />
          </div>
        </div>

        <!-- <h3>Filter By Price Range</h3>
        <label for="vol">Price (between 0 and 1000):</label>

        <input
          type="range"
          v-model="range"
          min="0"
          max="1000"
          step="10"
        />
        Selected : {{range}} -->
        <!-- <AppDashboardWallet
          style="display: none;"
          wallet="Save"
          :balance="formatPrice(getSaveWalletBalance)"
        /> -->
        <!-- <div class="store__sidebar__action">
          <AppStoreActionButton @storeActionType="getActionEventValue" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import AppSpendWallet from "@/components/AppSpendWallet";
import AppDashboardWallet from "../../components/AppDashboardWallet";
// import AppStoreActionButton from "@/components/AppStoreActionButton";
import AppCategoryItem from "../../components/AppCategoryItem";
import AppStoreSwipe from "../../components/AppStoreSwipe";
import MobileStoreItem from "../../components/MobileStoreItem";
import AppStoreItem from "../../components/AppStoreItem";
import AppButton from "../../components/AppButton";
// import AppDashboardWallet from "@/components/AppDashboardWallet";
import AppErrorMessage from "../../components/AppErrorMessage";
import config from "../../config";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  components: {
    AppDashboardWallet,
    // AppStoreActionButton,
    AppCategoryItem,
    AppStoreSwipe,
    AppStoreItem,
    MobileStoreItem,
    AppButton,
    // AppDashboardWallet,
    AppErrorMessage,
  },
  data() {
    return {
      productsToShow: 5,
      range: 1000,
      spendWallet: 3,
      section: "shop", //swipe
      search: null,
      selectedCategoryId: null,
      filtered_products: [],
      all_products: [],
      selectedProduct: [],
      selectedProductCurrentIndex: 0,
      categories: [],
      firstView: true,
      products: [],
      error: "",
    };
  },
  methods: {
    ...mapActions(["fetchGiftsStore", "fetchOcassions", "setupUser"]),
    resolveImage(src) {
      return config.HOST + src;
    },
    getProductImages(product) {
      if (product == null) return [];
      var pictures = [];
      if (product.default_picture_link)
        pictures[0] = product.default_picture_link;
      if (product.picture1_link) pictures[1] = product.picture1_link;
      if (product.picture2_link) pictures[2] = product.picture2_link;
      if (product.picture1_link) pictures[3] = product.picture2_link;
      return pictures;
    },
    getActionEventValue(value) {
      this.section = value;
    },
    filterItemsByKey() {
      var products = this.$store.state.categories[
        this.selectedProductCurrentIndex
      ].products;
      if (this.search != null && this.search.length > 2) {
        var i;
        this.$store.state.categories[
          this.selectedProductCurrentIndex
        ].products = [];
        for (i = 0; i < products.length; i++) {
          if (
            products[i].name.toLowerCase().search(this.search.toLowerCase()) >
            -1
          ) {
            this.$store.state.categories[
              this.selectedProductCurrentIndex
            ].products.push(products[i]);
          }
        }
        if (
          this.$store.state.categories[this.selectedProductCurrentIndex]
            .products.length == 0
        ) {
          this.error = "There are no products available in your search.";
          this.refreshProducts();
        }
      } else {
        this.fetchStore();
        // this.search = '';
        this.error = "";
      }
    },
    refreshProducts() {
      setTimeout(() => {
        this.fetchStore();
        this.search = "";
        this.error = "";
      }, 2000);
    },
    selectCategory(categoryId) {
      this.productsToShow = 5;
      var i;
      for (i = 0; i < this.takeGiftsCategories.length; i++) {
        if (this.takeGiftsCategories[i].id == categoryId) {
          this.takeGiftsCategories[i].bgColor = "#36D5F2";
          this.selectedCategoryId = this.takeGiftsCategories[i].id;
          this.selectedProductCurrentIndex = i;
        } else {
          this.takeGiftsCategories[i].bgColor = "#FFFFFF";
        }
      }
      this.search = "";
    },
    isSelected(category_id) {
      if (category_id == this.selectedCategoryId) return true;
      return false;
    },
  },
  computed: {
    ...mapGetters([
      "getSpendWalletBalance",
      "takeGiftsCategories",
      "getProductsFirstCategory",
      "getProductsFirstCategoryId",
      "getSaveWalletBalance",
      "childName",
    ]),
  },
  watch: {
    "$store.state.categories"(newCategories) {
      // console.log(oldCategories);
      // console.log(newCategories);
      this.$store.state.categories = newCategories;
    },
  },
  created() {
    const user = {
      userId: localStorage.getItem("children_user_id"),
      token: localStorage.getItem("children_token"),
      family_id_check: localStorage.getItem("children_family_id"),
    };
    this.setupUser(user);
    this.fetchGiftsStore();
    this.fetchOcassions();
    this.categories = this.takeGiftsCategories;
    this.selectedCategoryId = localStorage.getItem("giftsFirstCategory");
    setTimeout(() => {
      this.selectCategory(localStorage.getItem("giftsFirstCategory"));
    }, 1000)
    // console.log(this.selectedProductCurrentIndex);
  },
  mounted() {
    // this.takeCategories[0].bgColor = '#36D5F2';
    // this.categories = this.takeCategories;
    // this.filtered_products = JSON.parse(localStorage.getItem('productsFirstCategory')).products;
    // this.selectedCategoryId = JSON.parse(localStorage.getItem('productsFirstCategory')).id;
  },
};
</script>

<style lang="scss">
.store {
  // display: grid;
  // grid-template-columns: 10fr 3fr;
  height: 100%;

  &__container {
    height: 100%;
    overflow-y: scroll;

    &__items {
      width: auto;
      // overflow-y: auto;
      // overflow-x: auto;
      // display: grid;
      grid-template-rows: 1fr 3fr;
      &__title {
        padding-top: 30px;
        margin: auto;
        
        font-size: 50px;
        line-height: 24px;
        color: #7a615a;
      }

      &__top {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        margin-bottom: 40px;

        &__title {
          // padding-top: 40px;
          padding-top: 17px;
          margin-left: 10px;
          
          font-size: 40px;
          line-height: 24px;
          color: #7a615a;
          // margin-bottom: 40px;
        }

        &__search {
          margin-left: 10px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          &__input {
            width: 100%;
            height: 50px;
            margin-top: 20px;
            color: #7a615a;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 74px;
            outline: none;
            padding: 0px 30px;
            font: {
              size: 18px;
            }
          }
        }
      }

      &__categories {
        width: 100%;
        height: 95px;
        // margin-top: 30px;
        border-radius: 100px;
        background-color: #f8f5ed;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-flow: column;
        grid-auto-columns: minmax(250px, 1fr);
        overflow-x: auto;
        overflow-y: hidden;
        // margin-bottom: 30px;

        &::-webkit-scrollbar-track {
          background: #f8f5ed;
          border-radius: 40px;
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        // &::-webkit-scrollbar-thumb {
        //   background: linear-gradient(180deg, #36d5f2, #2ccbe8);
        //   border-radius: 30px;
        //   box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
        //     inset -2px -2px 2px rgba(0, 0, 0, 0.25);
        // }
        &::-webkit-scrollbar-corner {
          display: none;
        }

        &__item {
          padding: auto;
          height: 55px;
          width: 250px;
          margin-left: 20px;
          margin-right: 10px;
          background-color: white;
          margin-top: 20px;
          margin-bottom: 20px;
          border-radius: 50px;
        }
      }
      &__products {
        width: 100%;
        // display: grid;
        // grid-gap: 20px;
        // grid-template-columns: 1fr 1fr;
        background-color: #f8f5ed;
        // padding: 20px;
        border-radius: 40px;
        width: 100%;
        height: 430px;
        overflow: auto;
        box-sizing: border-box;
        // margin-top: 100px;
      }
    }
  }

  &__sidebar {
    // height: 100%;
    grid-gap: 40px;
    position: sticky;
    top: 0;
    // margin-left: 20px;
    // margin-right: 10px;
    display: block !important;
    grid-template-rows: 3fr 2fr;
  }
}

.store-subtitle {
  display: none;
}

.shopProductDetails__right__ocassions__icon {
  padding: 0;
  border-radius: 0;
  border: none;
  object-fit: contain;
}

@media screen and (max-width: 820px) {
  .store__sidebar {
    margin-top: 20px;
    margin-left: 0px;
  }
  .store-subtitle {
    display: block;
  }
  .mobile-wallet-shop {
    display: none;
  }
  .store__container__items__products {
    grid-gap: 10px;
  }
  // .appGoalsProgressBar__total {
  //   border: 1px solid rgba(0, 0, 0, 0.1);
  //   border-bottom-color: rgba(0, 0, 0, 0.34);
  //   text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  //   -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.34) inset,
  //     0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.08),
  //     0 3px 13px -1px rgba(0, 0, 0, 0.21);
  //   box-shadow: 0 1px 0 rgba(255, 255, 255, 0.34) inset,
  //     0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.08),
  //     0 3px 13px -1px rgba(0, 0, 0, 0.21);
  // }
  .storeItem {
    border: 5px solid #36d5f2;
    box-shadow: none;
  }
  .store__container__items__categories::-webkit-scrollbar {
    display: none;
  }
  .store__container__items__categories {
    height: auto !important;
    padding: 10px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .store__container__items__top__search__input {
    margin-top: 10px;
    color: #7a615a;
    border: 1px solid #e5e5e5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 74px;
    outline: none;
    padding: 0px 30px;
    font-size: 18px;
    box-shadow: none;
  }
  // .store__container__items__categories__item {
  //   height: 50px !important;
  //   margin: 0;
  // }
  .caregoryStoreItem {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-color: rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset,
      0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.01),
      0 3px 13px -1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset,
      0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.01),
      0 3px 13px -1px rgba(0, 0, 0, 0.1);
    .caregoryStoreItem__image {
      margin: 0;
      height: 20px;
      margin-right: 10px;
    }
    .caregoryStoreItem__text {
      font-size: 16px;
      margin: 0;
    }
  }
  .appGoalsProgressBar__text {
    font-size: 12px;
  }
  .shopProductDetails__left__image {
    height: 50vh;
    background-size: cover;
  }
  .shopProductDetails__left__images img {
    height: 60px;
    object-fit: cover;
  }
  .shopProductDetails__right__miniGrid__price {
    text-align: center;
    font-size: 4rem;
    margin: 2rem 0;
  }
  .shopProductDetails__right__info {
    padding-top: 5px;
  }
  .shopProductDetails__right__stars__star {
    width: auto !important;
  }

  .shopProductDetails__right__title {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .store__container__items__top__search__input {
    height: 48px;
  }

  .storeItems__top {
    display: grid;
    grid-template-columns: 1fr;
  }
  .store {
    // display: grid;
    grid-template-columns: 1fr;
    height: auto;
  }
  .storeItems__products {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 100px;
  }
  .storeItems {
    grid-template-rows: 1fr;
  }
  .store__container__items__title {
    margin-top: 10px;
  }
  .store__container__items__products {
    display: grid;
    grid-template-columns: 1fr;
    // margin-top: -200px;
  }
  .store__container__items {
    width: 100%;
    overflow-y: none;
  }
  .store__container__items__top {
    grid-template-columns: 1fr;

    &__title {
      margin: auto;
      margin-bottom: 20px;
    }
    &__search {
      margin-bottom: 10px;
    }
  }
  .store__container {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .store__container__items__top__title {
    margin: 30px 0 30px 0;
    padding: 0;
    text-align: left;
    font-size: 16px;
    font-weight: 100;
    line-height: 20px;
    small {
      font-size: 12px;
    }
  }
  .store,
  .store__container__items {
    display: block;
  }
  .store__container__items__categories__item {
    height: 75px;
  }
  .store__container__items__categories {
    height: 115px;
    margin: 10px 10px 40px 10px;
    width: auto;
  }
  .store__container {
    overflow-y: hidden;
  }
  .store__container__items__products {
    margin: 10px;
    width: unset;
  }
  .store__sidebar {
    display: block;
    margin: 0 10px 30px 10px;
  }
  .spendWallet {
    background: #ffe17e;
    border-radius: 40px;
  }
  .store__sidebar__spendWallet {
    margin-bottom: 10px;
  }
}
.goals__sidebar__wallet {
  height: 100%;
}

#search {
  -webkit-box-align: center;
  align-items: center;
  background: #f8f5ed;
  border-radius: 20px;
  height: 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  // margin: 0.5em 0;
  padding: 0.5em 0.5em 0.5em 1.5em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 10px !important;
  width: 100%;
  margin-top: 20px;
  // width: 500px;
}
#search:hover,
#search:focus {
  // background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) inset;
}
#search #button,
#search input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  outline: 0;
}
#search #button {
  cursor: pointer;
  padding: 0 0.25em;
  margin-right: 10px;
  margin-top: 2px;
}
#search input {
  -webkit-box-flex: 1;
  flex: 1;
  color: #7a615a59;
}
#search input::-webkit-input-placeholder {
  color: #7a615a59;
}
#search input::-moz-placeholder {
  color: #7a615a59;
}
#search input:-ms-input-placeholder {
  color: #7a615a59;
}
#search input::-ms-input-placeholder {
  color: #7a615a59;
}
#search input::placeholder {
  color: #7a615a59;
}
#search .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  display: none;
  padding: 0 0.25em;
}

#search.loading #button {
  display: none;
}
#search.loading .spinner {
  display: block;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.gifts-store-subtitle {
  font-size: 16px;
  margin-top: 10px;
}
.takemebacktitle {
  font-size: 20px;
  color: #f65042;
  font-weight: 900;
  margin: 0 0 2px 0;
}
.takemebackdesc {
  font-size: 12px;
  color: #7a615a;
  margin: 0;
}
</style>
