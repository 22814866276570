<template>
  <div
    class="cardWallet shadow"
    :style="{ background: walletType }"
  >
    <div :style="{ color: walletTypeText }">
      <div class="holder-desc">
        {{ wallet }} wallet
      </div>
      <div class="holder-price">
        <span class="poundSign">&pound;</span>{{ balance ? balance : 0 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balance: {
      // type: Number,
      // default: 0
    },
    wallet: {
      type: String,
      default: "",
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    walletType() {
      return this.wallet.name === "main" ? "#37d5f2" : "#37d5f2";
    },
    walletTypeText() {
      return this.wallet === "Spend" ? "#ffffff" : "#FFFFFF";
    },
  },
};
</script>

<style lang="scss" scoped>
.cardWallet {
  width: 80%;
  height: 130px;
  border-radius: 13px;
  position: relative;
  color: white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.holder-price {
  font-size: 30px;
  font-weight: 700;
}
.holder-desc {
  font-size: 14px;
  font-weight: 700;
  // letter-spacing: -0.3px;
}
.poundSign {
  font-size: 18px;
}
</style>
